import $ from 'jquery';

$(document).ready(function() {

	function setMainPaddingTop() {
		//if ($(document.body).hasClass('home')) return;
		$('main').css('paddingTop', $('header.page').height()+30);
	}
	setTimeout(function () {
		setMainPaddingTop()
	}, 100);
	setMainPaddingTop();
	$(window).resize(setMainPaddingTop);

	$(window).scroll(function() {
		var sticky = $('#header'),
		scroll = $(window).scrollTop();

		if (scroll >= 100) {
			sticky.addClass('fixed');
		} else {
			sticky.removeClass('fixed');

		}
	});

	// PADDING TOP TO MAIN


	//HAMBURGER
	
	$("#menu-toggle").click(function(e) {
	    e.preventDefault();
	    $("header").toggleClass("toggled");
	    $("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$("#sidebar-wrapper .close").click(function(e) {
	    e.preventDefault();
	    $("header").removeClass("toggled");
		$("#sidebar-wrapper").toggleClass("toggled");
	});
	
	$('.dropdown-menu > li > .dropdown-menu').parent().addClass('dropdown-submenu').find(' > .dropdown-item').attr('href', 'javascript:;').addClass('dropdown-toggle');
		$('.dropdown-submenu > a').on("click", function(e) {
			var dropdown = $(this).parent().find(' > .show');
			$('.dropdown-submenu .dropdown-menu').not(dropdown).removeClass('show');
			$(this).next('.dropdown-menu').toggleClass('show');
			e.stopPropagation();
		});
		$('.dropdown').on("hidden.bs.dropdown", function() {
		$('.dropdown-menu.show').removeClass('show');
	});

});